import React from 'react'
import ReactDOM from "react-dom";

const V3Features = () => {
    const RnRfeatures = [
        "Monetary awards",
        "Instant non-monetary recognition",
        "Automated greetings",
        "Social recognition feed",
        "Wall of fame",
        "Gamified leaderboard",
        "Team awarding",
        "Panel voting awards",
        "Employee nudges",
        "Configurable workflow",
        "LinkedIn sharing",
        "Long service awards",
        "Service yearbook"
    ]
    const RedemptionFeatures = [
        "Amazon store",
        "Experiences & holidays",
        "Gift vouchers",
        "Merchandise",
        "Multi purpose wallet",
        "Add on"
    ]
    const GLFeatures = [
        "Languages",
        "SOLI matrix currency conversion",
        "Multi currency budget",
        "Native currency redemption",
        "Company wide announcements"
    ]
    const AnalyticsFeatures = [
        "HR dashboard",
        "Manager dashboard",
        "Manager monthly summary",
        "Employee adoption analytics",
        "Team adoption analytics"
    ]
    const SIFeatures = [
        "LeenAI chatbot",
        "Monday.com",
        "Freshteams",
        "Microsoft Outlook app",
        "Microsoft Teams app",
        "Slack",
        "Yellow.ai",
        "ZOHO Connect",
        "WhatsApp Business"
    ]
    const HRISFeatures = [
        "Workday",
        "SAP SuccessFactors",
        "Darwinbox",
        "PeopleStrong",
        "ZOHO People",
        "Oracle HCM",
        "BambooHR"
    ]
    const SSOFeatures = [
        "Okta",
        "Azure",
        "Google",
        "Ping Identity",
        "ADFS"
    ]
    const BrandingFeatures = [
        "Corporate branding",
        "Registration landing page"
    ]
    const DSFeatures = [
        "GDPR - (EU) 2016/679",
        "ISO 27001:2013",
        "ISO 27001:2019",
        "SOC 2 Type 2"
    ]
  return (
    <section className='w-full flex place-content-center py-10 xl:py-16'>
            <div className='w-10/12 xl:max-w-7xl mx-auto'>
                <h2 className='homepage-heading text-center'>Key features that ensure your <span className="text-orange">R&R Program's success</span></h2>
                <div className='grid border border-gray-150 rounded-t max-w-5xl mx-auto mt-10 xl:mt-16 mb-0 dynamicGrid1fr2fr'>
                    <h2 className='lato text-2xl p-3 xl:pl-10'>Features</h2>
                    <h2 className='lato text-2xl p-3 xl:pl-10 border-l border-gray-150'>Includes</h2>
                </div>
                <div className='grid border border-t-0 border-gray-150 max-w-5xl mx-auto dynamicGrid1fr2fr'>
                    <h2 className='lato text-lg p-2 pl-3 mt-2 xl:pl-10 border-r border-gray-100'>Rewards & Recognition</h2>
                    <ul class="flex flex-col w-full place-content-center border-l border-gray-150">
                    {
                        RnRfeatures.map(i => {
                            return(
     
                                <div className='w-full border-b border-gray-150 pl-3 xl:pl-10'>
                                <li class="flex items-center xl:w-1/2 py-4">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">{i}</p>
                                    </div>
                                </li>
                            </div>
                          
                        )})
                    }
                      </ul>
                </div>
                <div className='grid border border-t-0 border-gray-150 max-w-5xl mx-auto dynamicGrid1fr2fr'>
                    <h2 className='lato text-lg p-2 pl-3 mt-2 xl:pl-10 border-r border-gray-100'>Redemption</h2>
                    <ul class="flex flex-col w-full place-content-center border-l border-gray-150">
                    {
                        RedemptionFeatures.map(i => {
                            return(
     
                                <div className='w-full border-b border-gray-150 pl-3 xl:pl-10'>
                                    <li class="flex items-center xl:w-1/2 py-4">
                                        <div class="w-1/12"><img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                            class="mt-1"
                                            width="20"
                                            height="20"
                                        /></div>
                                        <div class="w-10/12">
                                        <p class="pl-2 m-0 text-sm">{i}</p>
                                        </div>
                                    </li>
                                </div>
                          
                        )})
                    }
                      </ul>
                </div>
                <div className='grid border border-t-0 border-gray-150 max-w-5xl mx-auto dynamicGrid1fr2fr'>
                    <h2 className='lato text-lg p-2 pl-3 mt-2 xl:pl-10 border-r border-gray-100'>Global Capabilities</h2>
                    <ul class="flex flex-col w-full place-content-center border-l border-gray-150">
                    {
                        GLFeatures.map(i => {
                            return(
     
                                <div className='w-full border-b border-gray-150 pl-3 xl:pl-10'>
                                    <li class="flex items-center xl:w-1/2 py-4">
                                        <div class="w-1/12"><img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                            class="mt-1"
                                            width="20"
                                            height="20"
                                        /></div>
                                        <div class="w-10/12">
                                        <p class="pl-2 m-0 text-sm">{i}</p>
                                        </div>
                                    </li>
                                </div>
                          
                        )})
                    }
                      </ul>
                </div>
                <div className='grid border border-t-0 border-gray-150 max-w-5xl mx-auto dynamicGrid1fr2fr'>
                    <h2 className='lato text-lg p-2 pl-3 mt-2 xl:pl-10 border-r border-gray-100'>Analytics & Reporting</h2>
                    <ul class="flex flex-col w-full place-content-center border-l border-gray-150">
                    {
                        AnalyticsFeatures.map(i => {
                            return(
     
                                <div className='w-full border-b border-gray-150 pl-3 xl:pl-10'>
                                    <li class="flex items-center xl:w-1/2 py-4">
                                        <div class="w-1/12"><img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                            class="mt-1"
                                            width="20"
                                            height="20"
                                        /></div>
                                        <div class="w-10/12">
                                        <p class="pl-2 m-0 text-sm">{i}</p>
                                        </div>
                                    </li>
                                </div>
                          
                        )})
                    }
                      </ul>
                </div>
                <div className='grid border border-t-0 border-gray-150 max-w-5xl mx-auto dynamicGrid1fr2fr'>
                    <h2 className='lato text-lg p-2 pl-3 mt-2 xl:pl-10 border-r border-gray-100'>Social Integrations</h2>
                    <ul class="flex flex-col w-full place-content-center border-l border-gray-150">
                    {
                        SIFeatures.map(i => {
                            return(
     
                                <div className='w-full border-b border-gray-150 pl-3 xl:pl-10'>
                                    <li class="flex items-center xl:w-1/2 py-4">
                                        <div class="w-1/12"><img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                            class="mt-1"
                                            width="20"
                                            height="20"
                                        /></div>
                                        <div class="w-10/12">
                                        <p class="pl-2 m-0 text-sm">{i}</p>
                                        </div>
                                    </li>
                                </div>
                          
                        )})
                    }
                      </ul>
                </div>
                <div className='grid border border-t-0 border-gray-150 max-w-5xl mx-auto dynamicGrid1fr2fr'>
                    <h2 className='lato text-lg p-2 pl-3 mt-2 xl:pl-10 border-r border-gray-100'>HRMS/HRIS Integrations</h2>
                    <ul class="flex flex-col w-full place-content-center border-l border-gray-150">
                    {
                        HRISFeatures.map(i => {
                            return(
     
                                <div className='w-full border-b border-gray-150 pl-3 xl:pl-10'>
                                    <li class="flex items-center xl:w-1/2 py-4">
                                        <div class="w-1/12"><img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                            class="mt-1"
                                            width="20"
                                            height="20"
                                        /></div>
                                        <div class="w-10/12">
                                        <p class="pl-2 m-0 text-sm">{i}</p>
                                        </div>
                                    </li>
                                </div>
                          
                        )})
                    }
                      </ul>
                </div>
                <div className='grid border border-t-0 border-gray-150 max-w-5xl mx-auto dynamicGrid1fr2fr'>
                    <h2 className='lato text-lg p-2 pl-3 mt-2 xl:pl-10 border-r border-gray-100'>SSO Integration</h2>
                    <ul class="flex flex-col w-full place-content-center border-l border-gray-150">
                    {
                        SSOFeatures.map(i => {
                            return(
     
                                <div className='w-full border-b border-gray-150 pl-3 xl:pl-10'>
                                    <li class="flex items-center xl:w-1/2 py-4">
                                        <div class="w-1/12"><img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                            class="mt-1"
                                            width="20"
                                            height="20"
                                        /></div>
                                        <div class="w-10/12">
                                        <p class="pl-2 m-0 text-sm">{i}</p>
                                        </div>
                                    </li>
                                </div>
                          
                        )})
                    }
                      </ul>
                </div>
                <div className='grid border border-t-0 border-gray-150 max-w-5xl mx-auto dynamicGrid1fr2fr'>
                    <h2 className='lato text-lg p-2 pl-3 mt-2 xl:pl-10 border-r border-gray-100'>Branding</h2>
                    <ul class="flex flex-col w-full place-content-center border-l border-gray-150">
                    {
                        BrandingFeatures.map(i => {
                            return(
     
                                <div className='w-full border-b border-gray-150 pl-3 xl:pl-10'>
                                    <li class="flex items-center xl:w-1/2 py-4">
                                        <div class="w-1/12"><img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                            class="mt-1"
                                            width="20"
                                            height="20"
                                        /></div>
                                        <div class="w-10/12">
                                        <p class="pl-2 m-0 text-sm">{i}</p>
                                        </div>
                                    </li>
                                </div>
                          
                        )})
                    }
                      </ul>
                </div>
                <div className='grid border border-t-0 rounded-b border-gray-150 max-w-5xl mx-auto dynamicGrid1fr2fr'>
                    <h2 className='lato text-lg p-2 pl-3 mt-2 xl:pl-10 border-r border-gray-100'>Data & Security</h2>
                    <ul class="flex flex-col w-full place-content-center border-l border-gray-150">
                    {
                        DSFeatures.map(i => {
                            return(
     
                                <div className='w-full border-b border-gray-150 pl-3 xl:pl-10'>
                                    <li class="flex items-center xl:w-1/2 py-4">
                                        <div class="w-1/12"><img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                            class="mt-1"
                                            width="20"
                                            height="20"
                                        /></div>
                                        <div class="w-10/12">
                                        <p class="pl-2 m-0 text-sm">{i}</p>
                                        </div>
                                    </li>
                                </div>
                          
                        )})
                    }
                      </ul>
                </div>

            </div>
        </section>
  )
}
const renderV3Features = () => {
    if (typeof window !== "undefined") {
      let id = document.querySelector("#v3Features")
      if (id != null) {
        ReactDOM.render(<V3Features />, id)
      }
    }
  }
  
  setTimeout(renderV3Features, 100)

export default V3Features