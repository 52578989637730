import React, {useState, useEffect} from 'react'
import ReactDOM from "react-dom";

const ProductVideoComp = ({ YTurl, UTAlt }) => {

    const [vidWidth, setvidWidth] = useState('')
    const checkScreenWidth = () => {
        if(typeof window !== "undefined"){
            if(window.innerWidth <= 415) setvidWidth((90 * window.innerWidth)/100)

            else if(window.innerWidth <= 768) setvidWidth((70 * window.innerWidth)/100)
            
            else if(window.innerWidth <= 1466) setvidWidth((40 * window.innerWidth)/100)
            
            else setvidWidth((30 * window.innerWidth)/100)
        }
    }

    useEffect(() => {
        checkScreenWidth()
    }, [])

  return (
    <div className='py-3 rounded-md' id="prodvideo">
        <iframe className='mx-auto xl:mx-0 rounded-md' style={{ boxShadow: "1px 1px 15px rgba(0, 0, 0, 0.4)" }} width={vidWidth} height="300" src={YTurl} title={UTAlt} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  )
}

const renderProductVideoComp = () => {
    if (typeof window !== "undefined") {
      let id = document.querySelector("#prodVidComp")
      let vidURL = id?.getAttribute('data-youtube-url')
      let vidAlt = id?.getAttribute('data-alt')
      if (id != null && vidURL != null) {
        ReactDOM.render(<ProductVideoComp YTurl = {vidURL} UTAlt = {vidAlt} />, id)
      }
    }
  }
  
  setTimeout(renderProductVideoComp, 100)

export default ProductVideoComp